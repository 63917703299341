import { useFormik } from 'formik';
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { contactDetails } from '../../context/actions/contact';
import send_logo from '../../assets/images/contact/send.png'
import { Link } from 'react-router-dom';
import form_img from '../../assets/images/contact/contact-us.png'

const ContactForm=({ teamref }) => {


    const showToastMessage=(message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_CENTER
        });
    };

    const validationSchema=Yup.object().shape({
        name: Yup.string()
            .required('Required'),
        email: Yup.string().matches(/(\<|^)[\w\d._%+-]+@(?:[\w\d-]+\.)+(\w{2,})(\>|$)/, 'Enter a valid email')
            .required('Required'),
        message: Yup.string()
            .required('Required'),
        company: Yup.string()
            .required('Required'),
        phone: Yup.string().matches(/^[6-9]\d{9}$/, 'Enter a valid mobile number')
            .required('Required'),
    });

    const formik=useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
            company: "",
            phone: "",
        },
        validationSchema,
        onSubmit: (values) => {
            let formData=new FormData();
            formData.append("name", values.name);
            formData.append("email", values.email);
            formData.append("message", values.message);
            formData.append("company", values.company);
            formData.append("phone", values.phone);
            contactDetails({ formData })((response) => {
                if (response&&response.status) {
                    showToastMessage('Successfully Submitted!')
                    formik.handleReset()
                } else {
                    console.log("##########", response?.response?.data?.errors?.name?.[0]);
                    formik.setErrors({
                        name: response?.response?.data?.errors?.name?.[0],
                        email: response?.response?.data?.errors?.email?.[0],
                        message: response?.response?.data?.errors?.message?.[0],
                        company: response?.response?.data?.errors?.company?.[0],
                        phone: response?.response?.data?.errors?.phone?.[0],
                    })
                }
            });
        },
    });

    // Get the touched status for all form fields
    const {
        touched: { name, email, message, company, phone },
    }=formik;


    return (
        <React.Fragment>
            <ToastContainer />
            <div className='contact-form'>
                <div className='container'>
                    <section className='form-sec'>
                        <div className='container'>
                            <div className='form-content'>
                                <div className='row df-center'>
                                    <div className='col-lg-7'>
                                        <div className='form-wrapper'>
                                            <h6>Send us a Message</h6>
                                            <Form onSubmit={formik.handleSubmit}>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <Form.Group className="mb-3 ptb-10" controlId="formBasicEmail">
                                                            <Form.Control
                                                                className={name&&formik.errors.name? 'error-border':''}
                                                                name="name"
                                                                value={formik.values?.name}
                                                                onChange={formik.handleChange}
                                                                isValid={name&&!formik.errors.name}
                                                                type="text"
                                                                placeholder="Your Name"
                                                            />
                                                            {name&&formik.errors.name&&
                                                                <p className='error-content'>{name&&formik.errors.name}</p>
                                                            }
                                                            <Form.Control.Feedback type="invalid">{name&&formik.errors.name}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <Form.Group className="mb-3 ptb-10" controlId="formBasicEmail">
                                                            <Form.Control
                                                                className={email&&formik.errors.email? 'error-border':''}
                                                                name="email"
                                                                value={formik.values?.email}
                                                                onChange={formik.handleChange}
                                                                type="email"
                                                                placeholder="Email Address"
                                                                isValid={email&&!formik.errors.email}
                                                            />
                                                             {email&&formik.errors.email&&
                                                                <p className='error-content'>{email&&formik.errors.email}</p>
                                                            }
                                                            <Form.Control.Feedback type="invalid">{email&&formik.errors.email}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <Form.Group className="mb-3 ptb-10" controlId="formBasicEmail">
                                                            <Form.Control
                                                                className={phone&&formik.errors.phone? 'error-border':''}
                                                                name="phone"
                                                                value={formik.values?.phone}
                                                                onChange={formik.handleChange}
                                                                isValid={phone&&!formik.errors.phone}
                                                                type="text"
                                                                placeholder="Phone"
                                                            />
                                                             {email&&formik.errors.email&&
                                                                <p className='error-content'>{email&&formik.errors.email}</p>
                                                            }
                                                            <Form.Control.Feedback type="invalid">{phone&&formik.errors.phone}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <Form.Group className="mb-3 ptb-10" controlId="formBasicEmail">
                                                            <Form.Control
                                                                className={company&&formik.errors.company? 'error-border':''}
                                                                name="company"
                                                                value={formik.values?.company}
                                                                onChange={formik.handleChange}
                                                                isValid={company&&!formik.errors.company}
                                                                type="text"
                                                                placeholder="Company"
                                                            />
                                                              {company&&formik.errors.company&&
                                                                <p className='error-content'>{company&&formik.errors.company}</p>
                                                            }
                                                            <Form.Control.Feedback type="invalid">{company&&formik.errors.company}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='btn-wrap'>
                                                            <Form.Group className="mb-3 ptb-10" controlId="exampleForm.ControlTextarea1">
                                                                <Form.Label>Message</Form.Label>
                                                                <Form.Control
                                                                    className={message&&formik.errors.message? 'error-border':''}
                                                                    name="message"
                                                                    value={formik.values?.message}
                                                                    onChange={formik.handleChange}
                                                                    isValid={message&&!formik.errors.message}
                                                                    as="textarea"
                                                                    rows={5}
                                                                />
                                                                  {message&&formik.errors.message&&
                                                                <p className='error-content'>{message&&formik.errors.message}</p>
                                                            }
                                                                <Form.Control.Feedback type="invalid">{message&&formik.errors.message}</Form.Control.Feedback>
                                                            </Form.Group>
                                                            <div className='btn-wrapper'>
                                                                <Button variant="primary" type='submit'>
                                                                    <img src={send_logo} className="" alt="Earthood" id='header_image' />Submit
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 d-none d-lg-block'>
                                        <div className='img-wrapper'>
                                            <img src={form_img} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContactForm;