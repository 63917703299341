import React from 'react'
import how_icaf_works_image from "../../assets/images/icaf-works-img.png";

const HowIcafWorks = ({workref}) => {
    return (
        <React.Fragment>
            <div className='how-icaf-works' ref={workref} id='works'>
                <div className='container'>
                    <div className='row d-center'>
                        <div className='col-log-6 col-md-6 col-sm-12 col-12'>
                            <div className='left-block'>
                                <img src={how_icaf_works_image} alt="How it works" className='img-fluid' />
                            </div>
                        </div>
                        <div className='col-log-6 col-md-6 col-sm-12 col-12'>
                            <div className='right-block'>
                                <h2>How ICAF Works</h2>
                                <p>Regular engagement and continuous feedback are vital for maximizing the mutual benefits between The Forum and the Standards through this initiative. ICAF will establish consistent interaction with the voluntary carbon standards through various channels such as emails, webinars, and closed-door meetings held at IETA/COP conferences. Additionally, the Forum intends to actively participate in Stakeholder Meetings organized by voluntary carbon standards, where they will attend and discuss agenda-driven issues on the table. This proactive approach ensures a dynamic and collaborative relationship between The Forum and the voluntary carbon standards.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HowIcafWorks