import React, { useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

const Header = ({ workref,objectivesref,teamref,topref }) => {
const navigate = useNavigate()
const location=useLocation();

  const scrollToWorks = () => {
    if (workref && workref.current) {
      workref.current.scrollIntoView({behavior : "smooth"});
    }
  };
  const scrollToObjectives = () => {
    if (objectivesref && objectivesref.current) {
      objectivesref.current.scrollIntoView({behavior : "smooth"});
    }
  };
  const scrollToTeam = () => {
    if (teamref && teamref.current) {
      teamref.current.scrollIntoView({behavior : "smooth"});
    }
  };
const goHome = ()=>{
  window.scrollTo({top:0,left:0,behavior:'smooth'})
  navigate('/')
}



useEffect(() => {
  const section=new URLSearchParams(location.search).get('section');
  if (section) {
      const activeSection=document.getElementById(section);
      console.log(activeSection, activeSection.offsetTop, 'activeSection.offsetTopactiveSection.offsetTopactiveSection.offsetTopactiveSection.offsetTop');
      if (activeSection) {
          let topOffset=activeSection.offsetTop;
          if (section==='earthscoop') {
              topOffset=4652;
          }
          window.scrollTo({ top: topOffset, behavior: "smooth" });
      }
  }
}, [location]);



  return (
    <React.Fragment>
      <div className="header">
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="#home" onClick={()=>goHome()}>
              <img src={logo} alt="ICAF Logo" title="ICAF Logo" className="img-fluid" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                {/* <Nav.Link href="#activities" onClick={() => scrollToObjectives()}>ACTIVITIES</Nav.Link> */}
                <Link className='nav-link' to="/?section=activity" onClick={() => scrollToObjectives()}>ACTIVITIES</Link>
                <Link className='nav-link' to="/?section=works" onClick={() => scrollToWorks()}>HOW IT WORKS</Link>
                <Link className='nav-link' to="/?section=team" onClick={() => scrollToTeam()}>OUR TEAM</Link>
                {/* <Nav.Link href="#how_it_works" onClick={() => scrollToWorks()}>HOW IT WORKS</Nav.Link>
                <Nav.Link href="#our_team" onClick={() => scrollToTeam()}>OUR TEAM</Nav.Link> */}
                <Link to="/contact-us">CONTACT US</Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </React.Fragment>
  );
};

export default Header;
