import React, { useEffect, useRef } from 'react'
import Header from '../Layout/header'
import Footer from '../Layout/footer'
import Banner from './banner'
import Objectives from './objectives'
import HowIcafWorks from './how_icaf_works'
import OurTeam from './our_team'
import EsteemedMembers from './esteemed_members'

const Home = () => {
    const workref = useRef()
    const objectivesref = useRef()
    const teamref = useRef()
    const topref = useRef()

    const bottomRef = useRef(null);
    useEffect(() => {
        bottomRef.current?.scrollIntoView({ block: "start" });
    }, []);
  return (

    <div  ref={bottomRef}>
        <Header workref={workref} objectivesref={objectivesref} teamref={teamref} topref={topref} />
        <Banner topref={topref} />
        <Objectives objectivesref={objectivesref}/>
        <HowIcafWorks workref={workref} />
        <OurTeam teamref={teamref} />
        <EsteemedMembers/>
        <Footer/>
    </div>
  )
}

export default Home