import React from 'react'
import Slider from "react-slick"
import earthood_logo from "../../assets/images/earthood.png";
import epic_logo from "../../assets/images/epic.jpg";
import carbon_logo from "../../assets/images/carbon.png";
import kbs_logo from "../../assets/images/kbs.png";
import vku_logo from "../../assets/images/vku.jpeg";
import fork_logo from "../../assets/images/4k.jpg";
import nova from "../../assets/images/nova.jpg";
import scs from "../../assets/images/scs.jpg";
import sa from "../../assets/images/sa.png";
import envitance from "../../assets/images/envitance.jpg";
import ampere from "../../assets/images/ampere.jpg";

const EsteemedMembers=() => {
    var settings={
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite:true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <React.Fragment>
            <div className='esteemed-members-wrap'>
                <div className='container'>
                    <div className='row'>
                        <h2>Esteemed Members</h2>
                            <Slider {...settings}>
                                <div className='logo-block'>
                                    <img src={fork_logo} alt="" title="" className='img-fluid h-70' />
                                </div>
                                <div className='logo-block'>
                                    <img src={carbon_logo} alt="" title="" className='img-fluid carbon' />
                                </div>
                                <div className='logo-block'>
                                    <img src={earthood_logo} alt="" title="" className='img-fluid earthood' />
                                </div>
                                <div className='logo-block'>
                                    <img src={epic_logo} alt="" title="" className='img-fluid epic' />
                                </div>
                                <div className='logo-block'>
                                    <img src={kbs_logo} alt="" title="" className='img-fluid h-70' />
                                </div>
                                <div className='logo-block'>
                                    <img src={vku_logo} alt="" title="" className='img-fluid h-70' />
                                </div>
                                <div className='logo-block'>
                                    <img src={nova} alt="" title="" className='img-fluid nova mb-300' />
                                </div>
                                <div className='logo-block'>
                                    <img src={scs} alt="" title="" className='img-fluid carbon mb-300' />
                                </div>
                                <div className='logo-block'>
                                    <img src={sa} alt="" title="" className='img-fluid sa mb-300' />
                                </div>
                                <div className='logo-block'>
                                    <img src={envitance} alt="" title="" className='img-fluid carbon' />
                                </div>
                                <div className='logo-block'>
                                    <img src={ampere} alt="" title="" className='img-fluid carbon mb-200' />
                                </div>

                            </Slider>

                            {/* <div className='logo-block'>
                                <img src={fork_logo} alt="" title="" className='img-fluid h-70' />
                            </div>
                            <div className='logo-block'>
                                <img src={carbon_logo} alt="" title="" className='img-fluid w-70 carbon' />
                            </div>
                            <div className='logo-block'>
                                <img src={earthood_logo} alt="" title="" className='img-fluid w-70 earthood' />
                            </div>
                            <div className='logo-block'>
                                <img src={epic_logo} alt="" title="" className='img-fluid w-70 epic' />
                            </div>
                            <div className='logo-block'>
                                <img src={kbs_logo} alt="" title="" className='img-fluid h-70' />
                            </div>

                            <div className='logo-block'>
                                <img src={vku_logo} alt="" title="" className='img-fluid h-70' />
                            </div> */}

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EsteemedMembers