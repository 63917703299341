import React from 'react'
import banner_logo from "../../assets/images/icaf-large-logo.png";
import { Link } from "react-router-dom";

const Banner = ({topref}) => {
    return (
        <React.Fragment>
            <div className='banner' ref={topref}>
                <div className='container'>
                    <div className='row flex-column'>
                        <img src={banner_logo} alt="ICAF Logo" className='banner-logo img-fluid' />
                        <h1>A collaborative platform that serves as an interactive knowledge bridge among VVB, carbon standards &amp; project developers.</h1>
                        <Link to="/contact-us" className='btn btn-primary'>Contact Us</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Banner