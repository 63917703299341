import React from 'react'
import kaviraj_img from "../../assets/images/KavirajSingh.jpg";
import amit_img from "../../assets/images/amit.jpg";
import linkedin from '../../assets/images/linkedin.svg'
import { Link } from 'react-router-dom';
const OurTeam=({ teamref }) => {
    return (
        <React.Fragment>
            <div className='structure-wrap' ref={teamref} id='team'>
                <span className='structure-img'></span>
                <div className='container'>
                    <div className='row'>
                        <h2>Our Team</h2>
                        <div className='structure-image-wrap'>
                            <div className='image-block'>
                            <div className='img-wrapper'>
                                <img src={kaviraj_img} alt="Dr. Kaviraj Singh" title="Dr. Kaviraj Singh" className='img-fluid card-img' />
                                <div className='overlay'>
                                    <Link to='https://www.linkedin.com/in/kaviraj-singh-22489212/' target='_blank' className='linkedin-img'>
                                        <img src={linkedin} className='img-fluid' alt="linkedin icon"/>
                                        </Link>
                                    </div>
                                    </div>
                                <h4>Dr Kaviraj Singh</h4>
                                <h5>President</h5>
                            </div>
                            <div className='image-block'>
                                <div className='img-wrapper'>
                                    <img src={amit_img} alt="Amit Anand" title="Amit Anand" className='img-fluid card-img' />

                                    {/* <div className='overlay'>
                                    <Link to='' target='_blank' className='linkedin-img'>
                                        <img src={linkedin} className='img-fluid' alt="linkedin icon"/>
                                        </Link>
                                    </div> */}
                                </div>
                                <h4>Amit Anand</h4>
                                <h5>Vice-President</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default OurTeam