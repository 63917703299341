import React, { useEffect, useRef } from 'react'
import Header from '../Layout/header'
import Footer from '../Layout/footer'
import ContactBanner from './ContactBanner'

const ContactUs = () => {
  const bottomRef=useRef(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ block: 'start' });
}, [bottomRef.current]);


  return (


    <div ref={bottomRef}>
        <Header  />
        <ContactBanner />
        <Footer />
    </div>
  )
}

export default ContactUs