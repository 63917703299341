import { CONTACT_ENQUIRY_FORM } from "../../../constants/api";
import axiosInstance from "../../../helpers/axiosInstance";

export const contactDetails=(props) => onResponse => {
    try {
        axiosInstance.post(CONTACT_ENQUIRY_FORM, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

