import React from 'react'

const Objectives = ({objectivesref}) => {
    return (
        <React.Fragment>
            <div className='objectives' ref={objectivesref} id='activity'>
                <div className='container'>
                    <div className='row'>
                        <h2>Objectives of ICAF</h2>
                        <div className='objectives-blocks-wrap'>
                            <div className='blocks'>
                                <span className='icon collaboration_icon'></span>
                                <h3>Collaboration and Communication</h3>
                                <ul>
                                    <li>Collect and summarize feedback from verification bodies (VVBs) on challenges, standards, and methodologies.</li>
                                    <li>Submit feedback to carbon standards, fostering a collaborative relationship and contributing to continuous improvement.</li>
                                </ul>
                            </div>
                            <div className='blocks'>
                                <span className='icon capacity_icon'></span>
                                <h3>Capacity Building and Quality Enhancement</h3>
                                <ul>
                                    <li>Assist in organizing workshops and trainings to enhance the quality of assessments by VVBs.</li>
                                    <li>Work with carbon standards to identify gaps and address challenges for effective and efficient validation and verification processes.</li>
                                </ul>
                            </div>
                            <div className='blocks'>
                                <span className='icon rule_icon'></span>
                                <h3>Rule and Guideline Development</h3>
                                <ul>
                                    <li>Advocate for measures that preserve the credibility and transparency of carbon offset projects and transactions.</li>
                                    <li>Represent VVBs in policy-making processes, contributing expertise to develop effective carbon offset frameworks.</li>
                                </ul>
                            </div>
                            <div className='blocks'>
                                <span className='icon policy_icon'></span>
                                <h3>Policy Representation and Integrity</h3>
                                <ul>
                                    <li>Advocate for measures that preserve the credibility and transparency of carbon offset projects and transactions.</li>
                                    <li>Represent VVBs in policy-making processes, contributing expertise to develop effective carbon offset frameworks.</li>
                                </ul>
                            </div>
                            <div className='blocks'>
                                <span className='icon resource_icon'></span>
                                <h3>Resource Management and Support</h3>
                                <ul>
                                    <li>Provide workload forecasts to help carbon standards prepare and allocate resources efficiently.</li>
                                    <li>Offer additional support to standards, such as participating in research initiatives, pilot projects, or providing expertise in specific areas.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Objectives;