import React from 'react'
import ContactForm from './ContactForm'

const ContactBanner = ({teamref}) => {
    return (
        <React.Fragment>
            <div className='contact-banner'>
                <div className='container'>
                    <div className='row'>
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </div>
            <ContactForm />
        </React.Fragment>
    )
}

export default ContactBanner