import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/icaf-white.svg";
import linkedin_icon from "../../assets/images/linkedin.svg";


const Banner = () => {
    const navigate = useNavigate()
    const goHome = ()=>{
        window.scrollTo({top:0,left:0,behavior:'smooth'})
        navigate('/')
      }
    const goContact = ()=>{
        window.scrollTo({top:0,left:0,behavior:'smooth'})
        navigate('/contact-us')
      }
      

    return (
        <React.Fragment>
            <div className='footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='footer-top-section'>
                                <div className='left-block'>
                                    <Link to="/" onClick={()=>goHome()}>
                                        <img src={logo} alt="ICAF Logo" className='img-fluid footer-logo'/>
                                    </Link>
                                    <div className='d-flex'>
                                        <h5>Follow us on social networks</h5>
                                        <Link target='_blank' to="https://www.linkedin.com/company/internacional-carbon-auditor-forum/">
                                            <img src={linkedin_icon} alt="LinkedIn" className='img-fluid linkedin-icon'/>
                                        </Link>
                                    </div>

                                </div>
                                <div className='right-block'>
                                    <h3>Become a member</h3>
                                    <Link onClick={()=>goContact()} to="/contact-us" className='btn btn-primary'>Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-bottom-section'>
                    <div className='container'>
                        <div className='row mb-center'>
                            <div className='col-md-6'>
                                <p>Copyright &copy; 2023 ICAF. All Rights Reserved.</p>
                            </div>
                            <div className='col-md-6 txt-right'>
                                <p className='designed-text'>Designed & Developed by <Link to='https://www.aventusinformatics.com/' target="_blank">Aventus Informatics</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Banner